<h3 class="is-title is-size-3 has-text-weight-semibold">Mon récapitulatif<span class="has-text-secondary is-size-6" *ngIf="quote?.idOpportunity"> - Opportunité {{quote.idOpportunity}}</span></h3>
<main [data-cy]="'cart'" class="has-background-white-ter" role="main">
    <div class="main-content">
        <div [ngClass]="{'box is-clipped': !isCartEmpty && !isBareCart}">
            <div class="columns is-desktop is-multiline">
                <div *ngIf="!plan" class="column is-relative">
                    <tlv-empty-cart-item [showCmsModalLink]="false" data-cy="empty-cart-block-plan" [isCartEmpty]="isCartEmpty || isBareCart" [wallType]="'subscription'"
                                              itemColor="tertiary" itemIcon="tri-sim-card"
                                              itemLabel="Ajouter un Forfait"></tlv-empty-cart-item>
                </div>
                <div *ngIf="plan" class="column subscription-block-separator">
                    <span *ngIf="plan.isPremium && equipment" [ngClass]="isSas && 'is-sas'"
                          class="is-mandadory icon is-circled is-medium has-background-grey-light has-text-tertiary">
                        <i class="tri-plus"></i>
                    </span>
                    <tlv-product-block [product]="plan" [wallType]="'subscription'" [quoteIndex]="quoteIndex"></tlv-product-block>
                </div>
                <div *ngIf="!equipment" class="column">
                    <tlv-empty-cart-item data-cy="empty-cart-block-equipment" [isCartEmpty]="isCartEmpty || isBareCart" [wallType]="'equipment'"
                                              itemColor="tertiary" itemIcon="tri-mobile"
                                              itemLabel="Ajouter un Téléphone"></tlv-empty-cart-item>
                </div>
                <div *ngIf="equipment" [ngClass]="{'equipment-block-separator': !plan}" class="column">
                    <tlv-product-block [product]="equipment" [wallType]="'equipment'" [quoteIndex]="quoteIndex"></tlv-product-block>
                </div>
                <div *ngIf="isCartEmpty || isBareCart" class="column">
                    <tlv-empty-cart-item [showCmsModalLink]="false" data-cy="empty-cart-block-fai" [isCartEmpty]="isCartEmpty || isBareCart" [wallType]="'fai'"
                                              itemColor="tertiary" itemIcon="tri-box"
                                              itemLabel="Ajouter une Box"></tlv-empty-cart-item>
                </div>

            </div>
        </div>
        @if(manualPromos.length > 0 && !orderRecovery && !isCartEmpty) {
            <div class="family-pack">
                <tlv-family-pack [manualPromos]="manualPromos" [quoteIndex]="quoteIndex"
                    [automaticPromotionsSum]="automaticPromotionsSum" (promoChange)="onPromoChange()">
                </tlv-family-pack>
            </div>
        }
        <tlv-basic-crosssells [showProducts]="false" [type]="crosssellTypes.ACCESSORY"></tlv-basic-crosssells>
        <tlv-basic-crosssells [showProducts]="false" [type]="crosssellTypes.OPTION"></tlv-basic-crosssells>
        <div *ngIf="!isCartEmpty">
            <div *ngIf="plan | isSmartTV" class="columns">
                <div class="column">
                    <bytel-cms-block [addIndexHtmlToUrl]="false" [battlefront]="true" blockId="zone_panier_bbox_smart_tv_1"></bytel-cms-block>
                </div>
            </div>
            <form [formGroup]="cartForm" class="columns is-multiline">
                <div class="column is-full" *ngIf="this.cartForm.get('simChoice').enabled">
                    <tlv-sim-card-choice formControlName="simChoice"></tlv-sim-card-choice>
                </div>
                <div *ngIf="this.cartForm.get('portability').enabled" class="column is-full">
                    <tlv-portability formControlName="portability"></tlv-portability>
                </div>
                <div *ngIf="cartForm.get('parentalControl').enabled" class="column is-full">
                    <tlv-parental-control formControlName="parentalControl" [quoteIndex]="quoteIndex"></tlv-parental-control>
                </div>
                <div *ngIf="equipment && insurance" class="column is-full">
                    <tlv-insurance [insurance]=insurance formControlName="insuranceControl"></tlv-insurance>
                </div>
            </form>

            <tlv-cross-sell-plan *ngIf="isQuoteMixed && isFai && !hasSimo && !scoringRules && !orderRecovery"></tlv-cross-sell-plan>
            <div class="columns is-marginless">
                <div class="column is-full">
                    <div class="buttons is-centered">
                        <button data-cy="multi-quotes-add-fai-btn" (click)="checkDadThenGoToFai()" *ngIf="isQuoteMixed && isSimo && !hasFai" [disabled]="isLoading || scoringRules" class="is-secondary button">
                            <span bytelTranslate="panier.panier.valider">{{isLoading ? 'Traitement en cours...' : 'Ajouter une offre fixe'}}</span>
                        </button>
                        <button (click)="validateStep()" [disabled]="!cartForm.valid || isLoading" class="is-primary button" data-cy="cart-validate-btn">
                            <span *ngIf="!isPreorder" bytelTranslate="panier.panier.valider">{{isLoading ? 'Traitement en cours...' : 'Valider'}}</span>
                            <span *ngIf="isPreorder"bytelTranslate="panier.panier.precommander">{{isLoading ? 'Traitement en cours...' : 'Pré-commander'}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</main>
