<ng-container *ngIf="products.length">
    <p class="is-title is-size-3 has-text-weight-semibold">J'ajoute un forfait</p>
    <div class="columns is-mobile is-multiline crosssell-items">
        <ng-container *ngFor="let plan of products">
            <div class="column product-item is-one-third has-text-centered" >
                <div class="box plan-type">
                    <div>
                        <h3 class="text is-level-1 has-text-weight-semibold is-title-clipped">{{plan.nom}}</h3>
                        @if (plan?.eligibilites?.includes(TYPE_ELIGIBILITE.FOYER)) {
                            <p
                                class="is-secondary is-small tag is-inline-block">
                                B.iG
                            </p>
                        }
                        <ng-container *ngFor="let promoIncitation of plan.promotions">
                        @if (!promoIncitation.estDifferee && !promoIncitation.estIncitation &&
                        promoIncitation?.proprietesSupplementaires?.typesMarketing) {
                            <p class="is-secondary is-small tag is-inline-block">
                                {{promoIncitation.proprietesSupplementaires?.fanion || ''}}
                            </p>
                        }
                        </ng-container>
                        <ng-container *ngIf="plan.promotions.length else basePrice">
                            <div class="is-flex is-centered-desktop">
                                <tlv-price [isMainPrice]="true" [price]="plan.prix.final" class="is-margin" size-5></tlv-price>
                                <div *ngIf="plan.promotions.length && plan.prix?.duration">
                                    pendant {{plan.prix?.duration}} mois puis
                                    <tlv-price [isMainPrice]="true" [price]="plan.prix.pourToujours" class="is-margin" size-5></tlv-price>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #basePrice>
                            <tlv-price [isMainPrice]="true" [price]="plan.prix.final" class="is-margin" size-5></tlv-price>
                        </ng-template>
                        <div class="column"></div>
                    </div>
                    <div class="buttons is-multiline">
                        <ng-container *ngIf="!isInCart(plan)">
                            <button class="button is-small is-primary" [data-cy]="'plan-selection-'+plan.gencode" [disabled]="isLoading"
                                (click)="checkAccessoryAndRemove(plan)" *ngIf="['0','1'].includes(plan.blocage)">{{isLoading ?
                                'Traitement en cours...' : 'Sélectionner'}}</button>
                            <button class="button is-small is-tertiary" [data-cy]="'plan-locked-selection-'+plan.gencode" [disabled]="isLoading"
                                (click)="checkAccessoryAndRemove(plan, true)" *ngIf="['1','2'].includes(plan.blocage)">{{isLoading ?
                                'Traitement en
                                cours...' : 'Version bloquée'}}</button>
                        </ng-container>
                        <ng-container *ngIf="isInCart(plan)">
                            <button class="button is-small is-primary" [data-cy]="'plan-selection-'+plan.gencode" [disabled]="isLoading" (click)="removeProduct(plan)" >{{isLoading ? 'Traitement en cours...' : 'Supprimer'}}</button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="column"></div>
</ng-container>
