<div class="open-banking-box">
    <div class="columns is-mobile is-multiline is-marginless">
        <div class="column is-flex is-paddingless">
            <span data-cy="open-banking-title" class="is-size-3-tablet is-size-4 is-flex has-text-weight-bold is-vcentered">
                Open Banking
            </span>
        </div>
    </div>
    <div class="is-divider"></div>
    <div class="box">
        <div class="box-content">
            <div class="columns">
                <div class="column is-1">
                    <span class="icon is-medium open-banking-icon">
                        <i class="tri-credit-card"></i>
                    </span>
                </div>
                <div class="column">
                    <div class="step-label has-text-tertiary has-text-weight-semibold is-size-4">
                        Je récupère mon IBAN via ma banque
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-1"></div>
                <div class="column" [formGroup]="openBankingForm">
                    <div class="columns is-vcentered is-multiline">
                        <div class="column" >
                            <div class="rows">
                                <div class="row">
                                    <div class="columns is-justified-start is-aligned-center">
                                        <div class="column is-3">
                                            <div class="field">
                                                <div class="control">
                                                    <input data-cy="ob-sms" class="is-checkradio" id="use-sms" type="radio" [value]="OPEN_BANKING_COMMUNICATION_TYPE.SMS" formControlName="type">
                                                    <label for="use-sms">SMS</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column">
                                            <div class="field">
                                                <input
                                                    class="input"
                                                    formControlName="phone"
                                                    name="phone" id="phone"
                                                    mask="00 00 00 00 00"
                                                    placeholder="Numéro de contact*"
                                                    type="text"
                                                    data-cy="ob-phone"
                                                    required
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <ul *ngIf="openBankingForm.get('phone').invalid && openBankingForm.get('phone').touched" class="help is-danger"
                                        data-cy="ob-number-error">
                                        <li *ngIf="openBankingForm.get('phone').errors.required">Veuillez indiquer un numéro de contact</li>
                                        <li *ngIf="openBankingForm.get('phone').errors.pattern">Veuillez indiquer un numéro de contact valide</li>
                                    </ul>
                                </div>
                                <div class="row">
                                    <div class="columns is-justified-start is-aligned-center">
                                        <div class="column is-3">
                                            <div class="field">
                                                <div class="control">
                                                    <input data-cy="ob-email" class="is-checkradio" id="use-email" type="radio" [value]="OPEN_BANKING_COMMUNICATION_TYPE.MAIL" formControlName="type">
                                                    <label data-cy="" for="use-email">Email</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column">
                                            <div class="field">
                                                <div class="control has-dynamic-placeholder">
                                                    <input role="textbox" type="email" class="input is-default" id="email" maxlength="100" required
                                                        placeholder="Email de contact" value="" formControlName="email">
                                                    <label for="email">Email de contact</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <ul *ngIf="openBankingForm.get('email').invalid && openBankingForm.get('email').touched" class="help is-danger"
                                        data-cy="ob-number-error">
                                        <li *ngIf="openBankingForm.get('email').errors.required">Veuillez indiquer un email de contact</li>
                                        <li *ngIf="openBankingForm.get('email').errors.pattern">Veuillez indiquer un email de contact valide</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column is-flex is-flex is-aligned-end is-justified-end">
                    <div class="buttons open-banking-buttons">
                        <button
                            [disabled]="!openBankingForm.valid || isOpenBankingAlreadyPerformed"
                            class="is-primary button" (click)="submitOpenBanking()"
                            [ngClass]="{'is-loading': isLoading}"
                            data-cy="send-open-banking">
                            Envoyer
                        </button>
                        <button 
                            [disabled]="isOpenBankingAlreadyPerformed"
                            data-cy="refuse-open-banking" 
                            class="is-info button" 
                            [ngClass]="{'is-loading': isLoading}"
                            (click)="cancelOpenBanking()">
                            Refuser
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
