<div *ngIf="product?.data" class="is-flex is-multiline has-text-left-desktop has-text-centered">
    <!-- FAI -->
    <ng-container *ngIf="!is4GBox && !is5GBox">
        <div class="block-element">
            <!-- Range -->
            <p class="has-text-tertiary is-size-1-mobile is-size-2-tablet has-text-weight-semibold is-marginless">
                <span *ngIf="getProductLabel(product)" class="is-capitalized">{{getProductLabel(product)}}</span>
            </p>
            <!-- Plays -->
            <p class="is-size-6">
                <span>{{plays}}</span>
            </p>
            <!-- Obligation -->
            <p class="is-block is-size-5 has-text-weight-semibold" [ngClass]="{'is-marginless': veryHighSpeed}">{{product.data.obligation | engagementLabel}}</p>
            <p *ngIf="veryHighSpeed" class="is-block is-size-7" bytelTranslate="panier.forfait.fai.haut_debit_ftth">Très haut débit FTTH</p>
            <div class="is-divider has-background-white"></div>
            <!-- Data -->
            <p class="is-block is-size-3 has-text-weight-semibold is-marginless">{{product.data.data_envelope}}</p>
            <p class="range-label is-size-6" ng-if="isCustomDebit">
                <span class="is-block"><span bytelTranslate="panier.forfait.fai.debit.descendant">Débit descendant jusqu'à </span>{{speedDownLabel}}</span>
                <span class="is-block"><span bytelTranslate="panier.forfait.fai.debit.montant">Débit montant jusqu'à </span>{{speedUpLabel}}</span>
            </p>
        </div>
        <!-- BTv -->
        <div class="block-element">

            <div class="services-list columns is-marginless is-mobile is-vcentered is-centered-mobile is-centered-tablet-only">
                <div *ngIf="product.data.play !== '2P'" class="column is-narrow">
                    <span class="icon is-huge"><i class="tri-tv-btv"></i></span>
                </div>
            </div>

            <div class="columns is-marginless" *ngIf="hasTv">
                <div class="column is-paddingless">
                    <p class="is-size-6" bytelTranslate="panier.forfait.fai.btv">Vos chaines tv avec B.tv+</p>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- 4GBOX -->
    <ng-container *ngIf="is4GBox || is5GBox">
        <div class="block-element">
            <p class="has-text-tertiary is-size-1-mobile is-size-2-tablet has-text-weight-semibold is-marginless">
                <span class="icon is-huge" bytelTranslate="panier.forfait.fai.box_4G.box">
                    <i *ngIf="is4GBox" class="tri-4g"></i>
                    <i *ngIf="is5GBox" class="tri-5g"></i>
                    Box
                </span>
            </p>

            <p class="is-size-6">
                <span bytelTranslate="panier.forfait.fai.box_4G.internet">Internet<span *ngIf="is5GBoxTv">&nbsp;/ TV</span> </span>

            </p>
            <div class="columns is-marginless" *ngIf="is5GBoxTv">
                <div class="column is-paddingless">
                    <p class="is-size-6" bytelTranslate="panier.forfait.fai.btv">Vos chaines tv avec B.tv+</p>
                </div>
            </div>
            <p class="is-block is-size-5 has-text-weight-semibold" bytelTranslate="panier.forfait.fai.box_4G.sans_engagement">SANS ENGAGEMENT</p>
        </div>
    </ng-container>
    <div class="block-element">
        <div class="is-divider has-background-white"></div>
        <tlv-price [hasPeriod]="true" [price]="product?.prices?.final" class="is-margin" size-1
                   tertiary></tlv-price>
        <ng-container *ngIf="product?.prices?.duration">
            <p>Pendant <span class="has-text-weight-semibold">{{product?.prices?.duration}}</span> mois puis <tlv-price *ngIf="product?.prices?.final" [price]="product.prices.forever" class="is-margin" size-7></tlv-price></p>
        </ng-container>
        <div class="is-divider has-background-white"></div>
        <ng-container *ngFor="let promoIncitation of promotions">
            <div>
                <p *ngIf="promoIncitation?.typesMarketing | canDisplayFlag" class="is-secondary tag is-inline-block">
                    {{promoIncitation.proprietesSupplementaires?.fanion || promoIncitation.fanion || ''}}
                </p>
                <div class="columns is-vcentered" *ngIf="promoIncitation.odr && !promoIncitation.aUnePortabilite">
                    <div class="column is-narrow">
                        <span class="tag is-secondary">ODR</span>
                    </div>
                    <div class="column is-paddingless">
                        <p>{{promoIncitation?.proprietesSupplementaires?.etiquette}}</p>
                    </div>
                </div>
                <br>
            </div>
        </ng-container>
    </div>
    <div class="is-stretched block-element">
        <div class="is-divider"></div>
    </div>
    <div class="columns is-mobile address-infos">
        <div class="column is-narrow is-paddingless">
            <span class="icon is-medium"><i class="tri-marker"></i></span>
        </div>
        <div class="column">
            <p>
                <span class="is-block has-text-weight-semibold is-size-5" bytelTranslate="panier.forfait.fai.adresse">Adresse d'installation</span>
                <span class="is-block is-uppercase">{{userAddress}}</span>
            </p>
        </div>
    </div>
</div>



